@import "variables";

.v-btn {
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    border-radius: 8px;
}

.v-btn--round {
    border-radius: 50% !important;
}

.v-chip {
    border-radius: 8px !important;
    padding: 6px 12px;
    background: $secondaryContainerBackgroundColor !important;
}

.v-chip .v-chip__close {
    margin-left: 8px !important;
}

.v-autocomplete .v-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.v-autocomplete .v-label.v-label--active {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.v-autocomplete input, .v-autocomplete .v-select__selection {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.v-autocomplete .v-select__selections {
    margin-left: 8px;
}

.v-autocomplete div.v-input__slot {
    padding-left: 16px;
    padding-right: 12px;
}

// ds stands for Design System

.ds-h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: $koroidMidnightColor;
}

.ds-h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: $koroidMidnightColor;
}

.ds-h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: $koroidMidnightColor;
}

.ds-caption {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $koroidMidnightColor
}

.ds-elevation-2 {
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30) !important;
}

.ds-toolbar {
    background-color: $appBarBackgroundColor !important;
    padding: 16px 24px;
    height: unset !important;
    border-radius: 8px !important;
}

.ds-toolbar .v-toolbar__content {
    height: unset !important;
    padding: 0 !important;
}

.ds-toolbar .v-toolbar__title {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: $koroidMidnightColor;
}

.ds-toolbar-fab-button {
    color: $koroidMidnightColor;
    margin-left: 40px;
}

.ds-toolbar-action-button {
    height: 40px !important;
    background-color: $koroidPrimaryColor !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    letter-spacing: 0.5px;
}

.ds-dialog {
    border-radius: 16px !important;
}

.ds-modal-toolbar {
    background-color: $koroidSecondaryColor !important;
    border-radius: 16px 16px 0 0 !important;
}

.ds-modal-card {
    border-radius: 16px !important;
}

.ds-modal-content {
    padding: 24px !important;
}

.ds-modal-input-container {
    padding: 0 12px !important;
}

.ds-modal-input {
    padding: 0 !important;
}

.ds-modal-close-button {
    color: $koroidMidnightColor !important;
    height: 32px !important;
    width: 32px !important;
    margin-right: unset !important;
}

.ds-modal-close-button .v-icon {
    font-size: 30px !important;
    height: 30px !important;
    width: 30px !important;
}

.ds-chip-outlined {
    background-color: unset !important;
    border: 1px solid #777680 !important;
    color: #46464F !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.ds-expansion-panels {
    border-radius: 12px !important;
}

.ds-expansion-panel-header {
    background-color: #F4F3F7 !important;
    min-height: 56px !important;
    color: $koroidMidnightColor !important;
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.ds-expansion-panel-header:nth-child(1):last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.ds-expansion-panel.v-expansion-panel--active > .ds-expansion-panel-header {
    min-height: unset !important;
}

.ds-expansion-panel-content .v-expansion-panel-content__wrap {
    padding: 16px 24px !important;
}

.ds-active-chip {
    background: rgba(107, 201, 110, 0.4) !important;
}

.ds-inactive-chip {
    background: rgba(209, 52, 52, 0.4) !important;
}
