
// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Material+Icons');

@import "~vuetify/dist/vuetify.min.css";
@import "~@mdi/font/css/materialdesignicons.min.css";
@import "variables";
@import "koroidstyle";

/* Initial Loading Indicator */
[v-cloak] >* {
  display:none
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

[v-cloak]::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #ccc;
    border-top-color: #1B1464;
    animation: spinner 0.6s linear infinite;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}



.koroid.v-application .primary--text {
  color: #0D0A32 !important;
}

.koroid.v-application .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1px;
}

.koroid.v-application .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.v-btn {
    text-transform: none;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.50px;
}

.v-btn--icon.v-btn--round {
    width: 40px !important;
    height: 40px !important;
}

.v-btn:not(.v-btn--icon) {
    border-radius: 8px;
}

.v-btn__content {
    text-transform: capitalize !important;
}

.v-btn.v-btn--disabled > .v-btn__content {
    color: rgba(0,0,0,0.36);
}

.v-btn.primary {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30) !important;
}

.v-btn.primary:hover {
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30) !important;
}

.v-btn.secondary {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30) !important;
}

.v-btn.secondary:hover {
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30) !important;
}

.v-btn.secondary > .v-btn__content {
    color: #001E2E;
}

.v-btn.secondary > .v-btn__loader {
    color: #001E2E;
}

.v-btn.v-btn--outlined {
    border-color: #777680;
}

.v-btn.v-btn--outlined.v-btn--disabled {
    border-color: rgba(0,0,0,0.36);
}

.v-btn.v-btn--outlined.primary--text:hover {
    background-color: rgba(66, 83, 196, 0.08);
}

.v-btn.v-btn--outlined.primary--text > .v-btn__content {
    color: #4253C4;
}

.v-btn.v-btn--outlined.primary--text > .v-btn__loader {
    color: #4253C4;
}

.v-btn.v-btn--outlined.error--text:hover {
    background-color: rgba(186, 26, 26, 0.08);
}

.v-btn.v-btn--outlined.error--text > .v-btn__content {
    color: #BA1A1A;
}

.v-btn.v-btn--outlined.error--text > .v-btn__loader {
    color: #BA1A1A;
}

.v-btn.v-btn--text.primary--text:hover {
    background-color: rgba(66, 83, 196, 0.08);
}

.v-btn.v-btn--text.primary--text > .v-btn__content {
    color: #4253C4;
}

.v-btn.v-btn--text.primary--text > .v-btn__loader {
    color: #4253C4;
}

.v-btn.v-btn--text.error--text:hover {
    background-color: rgba(186, 26, 26, 0.08);
}

.v-btn.v-btn--text.error--text > .v-btn__content {
    color: #BA1A1A;
}

.v-btn.v-btn--text.error--text > .v-btn__loader {
    color: #BA1A1A;
}

div.v-input--switch__thumb {
    color: #777680 !important;
}

div.v-input--switch__track {
    border: 3px solid #777680;
}

div.v-input--switch__thumb.primary--text {
    background-color: #FFFFFF !important;
}

div.v-input--switch__track.primary--text {
    background-color: #4253c4;
    opacity: 1;
    border: 0;
}

div.v-input--switch > div.v-input__control > div.v-input__slot > label {
    color: #000D60 !important;
}

.v-btn-toggle {
    border-radius: 8px;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: #000000 !important;
}

.v-btn-toggle>.v-btn.v-btn {
    background-color: #FFFFFF;
    padding: 0 20px;
}

.v-btn-toggle>.v-btn.v-btn--active {
    background-color: #C8E6FF;
}

.v-btn-toggle>.v-btn.v-btn--active > .v-btn__content {
    color: #001E2E;
    column-gap: 5px;
}

.v-btn-toggle>.v-btn.v-btn:not(.v-btn--active) > .v-btn__content {
    color: #000000;
    column-gap: 5px;
}

.v-btn-toggle>.v-btn.v-btn--active > .v-btn__loader {
    color: #001E2E;
}

.v-btn-toggle>.v-btn.v-btn:not(.v-btn--active):hover {
    background-color: rgba(200, 230, 255, 0.08);
}

.v-btn-toggle>.v-btn.v-btn > .v-ripple__container {
    display: none !important;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
    height: 40px;
}

.v-time-picker-clock__ampm > .v-picker__title__btn {
    color: #4253C4;
}

.v-picker__title {
    color: #001E2E !important;
}

.v-date-picker-table > table > thead > tr > th {
    color: #001E2E !important;
}

.v-date-picker-table > table > tbody > tr > td > .v-btn:not(.v-btn--disabled):not(.v-btn--active) {
    color: #001E2E !important;
}

.v-date-picker-table > table > tbody > tr > td > .v-btn:not(.v-btn--disabled):not(.v-btn--active):focus:before {
    opacity: 0 !important;
}

.v-date-picker-table > table > tbody > tr > td > .v-btn.v-btn--disabled > .v-btn__content {
    color: #777680 !important;
}

.v-tabs-bar {
    background-color: #DFE0FF !important;
}

.v-tabs:not(.v-tabs--vertical) > .v-tabs-bar .v-tab {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: capitalize;
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.10px;
    text-transform: capitalize;
}

.fixed-menu-content-class {
    max-height: 190px !important;
}

.theme--dark.v-tabs>.v-tabs-bar .v-tab--disabled, .theme--dark.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active), .theme--dark.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn, .theme--dark.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon {
    color: rgba(0,13,96,0.8);
}

.theme--dark.v-tabs>.v-tabs-bar, .theme--dark.v-tabs>.v-tabs-bar .v-tab, .theme--dark.v-tabs>.v-tabs-bar .v-tab>.v-btn, .theme--dark.v-tabs>.v-tabs-bar .v-tab>.v-icon {
    color: rgb(0,13,96);
}

.v-tab:before, .v-tabs-slider {
    background-color: rgb(0,13,96) !important;
}

.v-stepper__step__step {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.50px;
}

.v-stepper__label {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.50px;
}

.v-stepper__step--active > .v-stepper__label {
    color: #4253C4 !important;
}

.v-stepper__step--active + .v-divider + .v-stepper__step.v-stepper__step--inactive > .v-stepper__step__step {
    background-color: #C8E6FF !important;
    color: #001E2E !important;
    border: 1px solid #001E2E;
}

.v-stepper__step--active + .v-divider + .v-stepper__step.v-stepper__step--inactive > .v-stepper__label {
    color: #001E2E !important;
}

.v-stepper__step--inactive > .v-stepper__step__step {
    background-color: #C8E6FF !important;
    color: rgba(0, 0, 0, 0.36) !important;
    border: 1px solid rgba(0, 0, 0, 0.36);
}

.v-stepper__step--inactive > .v-stepper__label {
    color: rgba(0, 0, 0, 0.36) !important;
}

.v-stepper__header {
    background-color: #C8E6FF !important;
    box-shadow: none !important;
}

.v-stepper__header .v-divider {
    border-color: #777680 !important;
}

.text--h1 {
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000 !important;
}

.text--h2 {
    font-family: "Roboto", sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0;
    color: #000000 !important;
}

.text--h3 {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0;
    color: #000000 !important;
}

.text--h4 {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000000 !important;
}

.text--h5 {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #000000 !important;
}

.text--label {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.50px;
    color: #000000 !important;
}

.text--paragraph {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000 !important;
}

.text--caption {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000 !important;
}

.text--body-sm {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000 !important;
}

.v-calendar .v-event-summary {
    color: #000000 !important;
}

.informational-modal {
    background-color: #C8E6FF !important;
    color: #001E2E !important;
}

.informational-modal > .v-toolbar__content > .v-btn--icon {
    height: 32px !important;
    width: 32px !important;
    margin-right: unset !important;
}

.informational-modal > .v-toolbar__content > .v-btn > .v-btn__content > i {
    color: #001E2E !important;
    font-size: 30px !important;
    height: 30px !important;
    width: 30px !important;
}

.destructive-modal {
    background-color: #E38585 !important;
    color: #1B1B1F !important;
}

.destructive-modal > .v-toolbar__content > .v-btn--icon {
    height: 32px !important;
    width: 32px !important;
    margin-right: unset !important;
}

.destructive-modal > .v-toolbar__content > .v-btn > .v-btn__content > i {
    color: #1B1B1F !important;
    font-size: 30px !important;
    height: 30px !important;
    width: 30px !important;
}

.warning-modal {
    background-color: #F7DBA3 !important;
    color: #1B1B1F !important;
}

.warning-modal > .v-toolbar__content > .v-btn--icon {
    height: 32px !important;
    width: 32px !important;
    margin-right: unset !important;
}

.warning-modal > .v-toolbar__content > .v-btn > .v-btn__content > i {
    color: #1B1B1F !important;
    font-size: 30px !important;
    height: 30px !important;
    width: 30px !important;
}

div.v-dialog > div.v-card {
    border-radius: 12px !important;
}

div.v-dialog > form > div.v-card {
    border-radius: 12px !important;
}

/* Validation Asterisk */
.required label::after {
    content: "*";
    color: #b71c1c;
}

/* Timezone Font */
.timezone-label {
    font-size: 13px;
    color: grey;
    padding-left: 15px;
}

.no-word-break {
    word-break: unset !important;
}

.koroid-horizontal-divider {
    border: 2px solid rgb(27, 20, 100);
    background-color: rgb(27, 20, 100);
}

#koroid-toast-container {
    position: fixed;
    z-index: 9999999;
    left: 50%;
    top: 25px;
    margin-left: -200px;
}

#koroid-toast-container > .toast-flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 400px;
    max-width: 400px;
}

#koroid-toast-container > .toast-element {
    visibility: hidden;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 15px 10px;
    margin-top: 10px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    box-shadow: 0 1px 2px 0 #505050cc;
}

#koroid-toast-container > .toast-element > .toast-flex-column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

#koroid-toast-container > .toast-element .toast-message {
    padding: 5px 10px;
    flex: 5;
}

#koroid-toast-container > .toast-element .toast-close-btn {
    cursor: pointer;
    color: #0D0A32;
    font-weight: bold;
    border-radius: 3px;
    padding: 5px 10px;
    vertical-align: middle;
}

#koroid-toast-container > .toast-element .toast-close-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

#koroid-toast-container > .toast-element.toast--show {
    visibility: visible;
    -webkit-animation: fadein 0.3s;
    animation: fadein 0.3s;
}

#koroid-toast-container > .toast-element.toast--show.toast--success, .toast-element.toast--show.toast--info {
    -webkit-animation: fadein 0.3s, fadeout 0.3s 2.7s;
    animation: fadein 0.3s, fadeout 0.3s 2.7s;
}

#koroid-toast-container > .toast-element.toast--success {
    background-color: #dee0fd;
    color: #0D0A32;
}

#koroid-toast-container > .toast-element.toast--warning {
    background-color: #F2C266;
    color: #0D0A32;
    text-align: left;
}

#koroid-toast-container > .toast-element.toast--error {
    background-color: #D13434;
    text-align: left;
}

#koroid-toast-container > .toast-element.toast--info {
    background-color: #2196f3;
}

#koroid-toast-container > .toast-element.toast--error .toast-close-btn {
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
}

#koroid-toast-container > .toast-element.toast--warning .toast-close-btn {
    text-align: center;
    color: #0D0A32;
    border: 1px solid #0D0A32;
}

.koroid-tooltip {
    opacity: 0.95;
    top: 773px;
    width: 230px;
    font-size: 12px;
    padding: 10px 15px;
    background: #E5E5E5;
    border-radius: 6px;
    border: 1px solid #7B7F98;
    color: #000;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);
}

.koroid-tooltip-no-fixed-width {
    width: unset !important;
}

@-webkit-keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 25px; opacity: 1;}
}

@keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 25px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {top: 25px; opacity: 1;}
  to {top: 0; opacity: 0;}
}

@keyframes fadeout {
  from {top: 25px; opacity: 1;}
  to {top: 0; opacity: 0;}
}

.autocomplete-item-disabled-subtitle {
    color: rgba(0,0,0,.38);
}

.autocomplete-item-disabled-subtitle span {
    padding-top: 1px;
    font-size: 12px;
}

